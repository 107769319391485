import moment from 'moment'  
import DATA from "./DATA" 
import * as mbs from "./MBS_actions"
import { toNumber } from './formatNumber'
let NAME = "mbs_dayshift" 


let live_orders = null
let local_orders = null
let all_orders = null
let shift = null
 
//cashups
const cashups = (shift)=>{ 
    if(!shift){return null}
    const cashups_obj = shift?.cashups
    const cashups = cashups_obj?Object.entries(cashups_obj).map(([key,value])=>({
        ...value,
        key:key
    })):null
    const running_cashups = cashups?cashups.filter(item=>!item.closed):null
    const closed_cashups = cashups?cashups.filter(item=>item.closed):null
    return {
        cashups:cashups,
        running_cashups:running_cashups,
        closed_cashups:closed_cashups,
    }
} 
const running_cashups = (shift)=>{  
    return  cashups(shift)?.running_cashups
} 
const closed_cashups = (item)=>{  
    return   cashups(shift)?.closed_cashups
} 


//orders
const sale_orders = (payload)=>{  
    return  new Promise((resolve,reject)=>{
        let response = {
            payload : payload,
            from : "sale_orders",
        }
        setTimeout(() => {
            try {
                shift = payload?.shift
                live_orders = payload?.live_orders
                local_orders = payload?.local_orders
                all_orders = merge_orders(live_orders,local_orders)
                if(!all_orders){
                    reject({...response,error:'no orders'}) 
                    return
                }
                
                let multiple_pay_orders = all_orders.filter(order=>order.input_payments) 
                let cash_orders = all_orders.filter(order=>order.account_type == 'cash' || order.payment_option_key == 'cash') 
                let other_payments_orders = all_orders.filter(order=>order.account_type == 'payment_option' && order.payment_option_key != 'cash') 
                let _input_payments = input_payments(multiple_pay_orders)
                let _input_cash_payments = input_cash_payments(multiple_pay_orders)
                let _input_other_payments = input_other_payments(multiple_pay_orders)
                
                resolve({
                    success:true,
                    orders:all_orders,
                    multiple_pay_orders:multiple_pay_orders,
                    cash_orders:cash_orders,
                    other_payments_orders:other_payments_orders,
                    input_payments:_input_payments,
                    input_cash_payments:_input_cash_payments,
                    input_other_payments:_input_other_payments,

                }) 
            } catch (error) {
                reject({
                    ...response,
                    error:error
                })
            } 
        }, 100);
    })
} 
const merge_orders = (live,local)=>{ 
    if(!live && !local){return null}
    if(live && !local){return live}
    if(!live && local){return local}
    let all = []
    if (live) {
        all.push(...live)
    }
    if (local) {
        array.forEach(order => {
            let index = all.findIndex(item=>item.key ==order.key)
            if (index == -1) {
                all.push(order)
            }else{
                all[index] = {
                    ...all[index],
                    ...order
                }
            } 
        }); 
    } 
    return  all
}
const input_payments = (orders)=>{  
    let amounts = []
    if(orders){
        orders.forEach(order => {
            const total_given_amount = toNumber(order.given_amount)
            const total_paid_amount = toNumber(order.sum_amount?order.sum_amount:order.paid_amount)
            const change_amount = total_given_amount-total_paid_amount
            const discount = toNumber(order.total_discount)
            const input_payments = order.input_payments
            const account_type = order.account_type
            if (input_payments&&account_type == 'multiple') {
                for (let index = 0; index < input_payments.length; index++) {
                    const element = input_payments[index];
                    let given_amount = toNumber(element.given_amount)
                    if (element.key == 'cash') {
                        given_amount=given_amount-change_amount
                    }
                    let indexOf = amounts.findIndex(item=>item.key==element.key)
                    if (indexOf==-1) {
                        amounts.push({
                            ...element,
                            given_amount:given_amount
                        })
                    } else {
                        amounts[indexOf].given_amount = amounts[indexOf].given_amount+given_amount
                    }

                } 
            }
        });
    }  
    return amounts 
} 
const input_cash_payments = (orders)=>{ 
    let payments = input_payments(orders)
    if(!payments){return 0}
    let cash = payments.find(pay=>pay.key=="cash") 
    return cash?cash.given_amount:0  
} 
const input_other_payments = (orders)=>{ 
    let payments = input_payments(orders)
    if(!payments){return 0}
    let amount = 0
    payments.forEach(pay => {
        if (pay.key!="cash") {
            amount = amount+pay.given_amount
        }
    }); 
    return amount 
} 
const cashup_orders = (item)=>{ 
    
    return  
} 
const cash_orders = (item)=>{ 
    
    return  
} 
const on_account_orders = (item)=>{ 
    
    return  
}
const other_payment_orders = (item)=>{ 
    
    return  
}
const multiple_payments_orders = (item)=>{ 
    
    return  
} 
 
export { 
    running_cashups, 
    closed_cashups,
    cashups,
    sale_orders,
    cashup_orders,
    cash_orders,
    on_account_orders,
    other_payment_orders,
    multiple_payments_orders, 
};